body{
    font-family: 'Courier New', Courier, monospace;
    h2{
        font-size: 3em;
        font-weight: 500;
    }
}

.active-row{
    font-weight: bold;
}

ion-button{
    text-transform: none;
    &.small{
        min-width:100px;
    }
    &.medium{
        height: 60px;
        min-width:250px;
        margin: 10px;
        span{
            font-size: 2em;
        }
    }
    &.large{
        height: 60px;
        min-width:300px;
        margin: 10px;
        span{
            font-size: 2em;
        }
    }
}

.happy-input{
    display: block;
    width: 250px;
    height: 50px;
    border: 2px solid var(--ion-color-primary);
    border-radius: 25px;
    padding: 10px;
    margin: 10px auto 10px auto;
    &:focus{
        outline: none;
    }
}

.centred-form{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .logo-img{
        margin: 20px;
        width: 120px;
        height: 120px;
    }
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    h1{
        font-size: 2.5em;
    }
    h2{
        font-size: 2em;
    }
    h3{
        font-size: 1.7em;
    }
    h4{
        font-size: 1.5em;
    }
}

.centred-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.batch-tile-heating{
    padding: 10px;
    width: 100%;
    background-color: var(--ion-color-danger);
    color: white;
}

.batch-tile-cooling{
    padding: 10px;
    width: 100%;
    background-color: var(--ion-color-warning);
    color: white;
}

.batch-tile-ice{
    padding: 10px;
    width: 100%;
    background-color: var(--ion-color-warning);
    color: white;
}

.batch-tile-maintaining{
    padding: 10px;
    width: 100%;
    background-color: var(--ion-color-success);
    color: white;
}

.batch-tile{
    padding: 10px;
    width: 100%;
    background-color: var(--ion-color-primary);
    color: white;
}

